import * as echarts from "echarts";
export const excellent = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },

  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      // data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
    },
  ],
};

export const range = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },

  series: [
    {
      name: "分数段占比",
      type: "pie",
      radius: "65%",
      center: ["51%", "43%"],
      data: [
        // { value: 0, name: "95~100分" },
        // { value: 0, name: "90~95分" },
      ],
      color: ["#5D77F9", "#84B7F9", "#5F9CF8"],

      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 14,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const range_grade = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "3%",
    right: "8%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      // "一年级",
      // "二年级",
      // "三年级",
      // "四年级",
      // "五年级",
      // "六年级",
      // "七年级",
      // "八年级",
      // "九年级",
    ],
  },
  series: [
    {
      name: "分班级优秀人数（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

export const qualified = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },

  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (record) {
          return ` ${record.value}人`;
        },
      },
    },
  ],
};

export const range_qualified = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  series: [
    {
      name: "等级人数占比",
      type: "pie",
      radius: "65%",
      center: ["50%", "45%"],
      data: [
        // { value: 0, name: "优秀" },
        // { value: 0, name: "良好" },
        // { value: 0, name: "及格" },
      ],
      color: ["#5D77F9", "#84B7F9", "#5F9CF8"],

      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 14,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const range_grade_qualified = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "3%",
    right: "8%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      // "一年级",
      // "二年级",
      // "三年级",
      // "四年级",
      // "五年级",
      // "六年级",
      // "七年级",
      // "八年级",
      // "九年级",
    ],
  },
  series: [
    {
      name: "分班级合格人数（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

export const edge_excellent = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },

  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
    },
  ],
};

export const range_edge_excellent = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  series: [
    {
      name: "优秀边缘人数占比",
      type: "pie",
      radius: "65%",
      center: ["50%", "43%"],
      data: [],
      color: ["#5D77F9", "#84B7F9", "#5F9CF8"],

      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 14,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const range_grade_edge_excellent = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "3%",
    right: "8%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      // "一年级",
      // "二年级",
      // "三年级",
      // "四年级",
      // "五年级",
      // "六年级",
      // "七年级",
      // "八年级",
      // "九年级",
    ],
  },
  series: [
    {
      name: "分班级优秀边缘人数（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

export const edge_qualified = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },

  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        show: true,
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
    },
  ],
};

export const range_edge_qualified = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  series: [
    {
      name: "及格边缘人数",
      type: "pie",
      radius: "65%",
      center: ["50%", "43%"],
      data: [],
      color: ["#5D77F9", "#84B7F9", "#5F9CF8"],

      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 14,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const range_grade_edge_qualified = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "3%",
    right: "8%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      // "一年级",
      // "二年级",
      // "三年级",
      // "四年级",
      // "五年级",
      // "六年级",
      // "七年级",
      // "八年级",
      // "九年级",
    ],
  },
  series: [
    {
      name: "分班级及格边缘人数",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

export const failed = {
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },

  grid: {
    top: "10%",
    left: "3%",
    right: "4%",
    bottom: "3%",
    containLabel: true,
  },

  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: "value",
    // show: false,
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  color: ["#226BFF", "#68BBC4"],
  series: [
    {
      type: "line",
      stack: "Total",
      data: [0, 0, 0, 0, 0, 0, 0],
      label: {
        color: "#226BFF",
        show: true,
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
    },
  ],
};

export const range_failed = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  series: [
    {
      name: "不及格人数占比",
      type: "pie",
      radius: "65%",
      center: ["50%", "45%"],
      data: [],
      color: ["#5D77F9", "#84B7F9", "#5F9CF8"],

      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 14,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
    },
  ],
};

export const range_grade_failed = {
  title: {
    text: "",
    bottom: "5",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "16px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `${record[0].name} \n ${record[0].value}人`;
    },
  },
  grid: {
    top: "5%",
    left: "3%",
    right: "8%",
    bottom: "15%",
    containLabel: true,
  },
  color: [
    "#5D77F9",
    "#84B7F9",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  xAxis: {
    type: "value",
    boundaryGap: [0, 0.01],
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  yAxis: {
    type: "category",
    data: [
      // "一年级",
      // "二年级",
      // "三年级",
      // "四年级",
      // "五年级",
      // "六年级",
      // "七年级",
      // "八年级",
      // "九年级",
    ],
  },
  series: [
    {
      name: "分班级不及格人数（2022-2023学年）",
      type: "bar",
      label: {
        show: true,
        position: "right",
        color: "#226BFF",
        formatter: function (record) {
          return `${record.value}人`;
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

var sex_ratioData = [
  { value: 0, name: "男" },
  { value: 0, name: "女" },
];

export const sex_ratio = {
  title: {
    text: "性别分布",
    top: "10",
    left: "left",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
  },
  legend: {
    top: "50%",
    right: "50px",
    orient: "vertical",
    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < sex_ratioData.length; i++) {
        // value = grade_ratioData[i].value;
        if (name == sex_ratioData[i].name) {
          return name + sex_ratioData[i].value;
        }
      }
    },
  },
  series: [
    {
      name: "性别分布",
      type: "pie",
      radius: ["50%", "70%"],
      center: ["30%", "60%"],
      avoidLabelOverlap: false,
      color: ["#5D77F9", "#FF46B3"],
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 16,
          //   fontWeight: "bold",
          position: "center",
        },
      },
      labelLine: {
        show: false,
      },
      data: sex_ratioData,
    },
  ],
};
var grade_ratioData = [
  { value: 0, name: "一年级" },
  { value: 0, name: "二年级" },
  { value: 0, name: "三年级" },
  { value: 0, name: "四年级" },
  { value: 0, name: "五年级" },
  { value: 0, name: "六年级" },
  { value: 0, name: "七年级" },
  { value: 0, name: "八年级" },
  { value: 0, name: "九年级" },
];
export const grade_ratio = {
  title: {
    text: "年级分布",
    top: "10",
    left: "left",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}人`;
    },
  },
  legend: {
    top: "20%",
    right: "0",
    orient: "vertical",

    formatter: function (name) {
      // 在这里编写自定义的格式化逻辑
      let value;
      for (let i = 0; i < grade_ratioData.length; i++) {
        // value = grade_ratioData[i].value;
        // console.log(i, name);
        if (name == grade_ratioData[i].name) {
          return name + grade_ratioData[i].value;
        }
      }
    },
  },
  series: [
    {
      name: "年级分布",
      type: "pie",
      radius: ["50%", "70%"],
      center: ["23%", "60%"],
      avoidLabelOverlap: false,
      label: {
        show: false,
      },
      emphasis: {
        label: {
          show: false,
          fontSize: 16,
          //   fontWeight: "bold",
          position: "center",
        },
      },
      labelLine: {
        show: false,
      },
      data: grade_ratioData,
    },
  ],
};

// 详情页面
export const evaluation = {
  radar: {
    // shape: 'circle',
    indicator: [
      { name: "师德师风", max: 120 },
      { name: "工作能力", max: 120 },

      { name: "工作业绩", max: 120 },
      { name: "体质健康", max: 120 },
    ],
    name: {
      rich: {
        a: {
          color: "#444",
          lineHeight: 10,
        },
      },
      formatter: (a, b) => {
        // return `{a|${a}}\n{b|${b.max}%}`;
        return `{a|${a}}`;
      },
    },
  },
  color: [
    "#7AC3CB",
    "#2478F2",
    "#5F9CF8",
    "#84B7F9",
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",

    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  series: [
    {
      name: "师德师风",
      type: "radar",
      data: [
        {
          value: [0, 0, 0, 0],
          name: "师德师风",

          label: {
            normal: {
              show: true,
              color: "#7AC3CB",
              formatter: function (params) {
                return params.value;
              },
            },
          },
          areaStyle: {
            color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
              {
                color: "#7AC3CB",
                offset: 0,
              },
              {
                color: "#7AC3CB",
                offset: 1,
              },
            ]),
          },
        },
        {
          value: [0, 0, 0, 0],
          name: "体质健康",
          label: {
            normal: {
              color: "#2478F2",

              show: true,
              formatter: function (params) {
                return params.value;
              },
            },
          },
          areaStyle: {
            color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
              {
                color: "#2478F2",
                offset: 0,
              },
              {
                color: "#2478F2",
                offset: 1,
              },
            ]),
          },
        },
      ],
    },
  ],
};

export const atten_class = {
  title: {
    text: "体育课出勤率",
    bottom: "0",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}%</br> ${record[1].seriesName} \n ${record[1].value[2]}%`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["9月", 0, 0],
      ["10月", 0, 0],
      ["11月", 0, 0],
      ["12月", 0, 0],
      ["1月", 0, 0],
      ["2月", 0, 0],
      ["3月", 0, 0],
      ["4月", 0, 0],
      ["5月", 0, 0],
      ["6月", 0, 0],
    ],
  },
  xAxis: { type: "category" },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};

export const atten_recess = {
  title: {
    text: "大课间出勤率",
    bottom: "0",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",

    formatter: function (record) {
      return `
    ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}%</br> ${record[1].seriesName} \n ${record[1].value[2]}%`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["9月", 0, 0],
      ["10月", 0, 0],
      ["11月", 0, 0],
      ["12月", 0, 0],
      ["1月", 0, 0],
      ["2月", 0, 0],
      ["3月", 0, 0],
      ["4月", 0, 0],
      ["5月", 0, 0],
      ["6月", 0, 0],
    ],
  },
  xAxis: { type: "category" },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};

export const sports_arts = {
  title: {
    text: "体艺2+1达成率",
    bottom: "0",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  legend: {
    top: "5%",
  },
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (record) {
      return `
  ${record[0].name}</br> ${record[0].seriesName} \n  ${record[0].value[1]}%</br> ${record[1].seriesName} \n ${record[1].value[2]}%`;
    },
  },
  color: [
    "#5D77F9",
    "#A8DBCE",
    "#E5F3FF",
    "#5F9CF8",
    "#E5F3FF",
    "#90B5FF",
    "#5087EC",
    "#2478F2",
    "#8BD0DB",
    "#67B3E8",
    "#6DC8E1",
    "#B6E3F0",
  ],
  dataset: {
    source: [
      ["product", "2022-2023学年", "2021-2022学年"],
      ["9月", 0, 0],
      ["10月", 0, 0],
      ["11月", 0, 0],
      ["12月", 0, 0],
      ["1月", 0, 0],
      ["2月", 0, 0],
      ["3月", 0, 0],
      ["4月", 0, 0],
      ["5月", 0, 0],
      ["6月", 0, 0],
    ],
  },
  xAxis: { type: "category" },
  yAxis: {
    splitLine: {
      //坐标轴在grid区域中的分割线
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  // Declare several bar series, each will be mapped
  // to a column of dataset.source by default.
  series: [
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#5D77F9",
        formatter: (value) => {
          return value.value[1] + "%";
        },
      },
    },
    {
      type: "bar",
      label: {
        show: true,
        position: "top",
        color: "#A8DBCE",
        formatter: (value) => {
          return value.value[2] + "%";
        },
      },
    },
  ],
};

export const rate_health = {
  title: {
    text: "体质健康达标率",
    bottom: "0",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    formatter: function (record) {
      return `
        ${record[0].name}
        </br> ${record[0].seriesName} \n  ${record[0].value}%
        </br> ${record[1].seriesName} \n ${record[1].value}%
        </br> ${record[2].seriesName} \n  ${record[2].value}%
        </br> ${record[3].seriesName} \n  ${record[3].value}%`;
    },
  },
  legend: {},
  grid: {
    top: "10%",
    left: "4%",
    right: "4%",
    bottom: "10%",
    containLabel: true,
  },
  xAxis: [
    {
      type: "category",
      data: [],
    },
  ],
  yAxis: [
    {
      type: "value",
    },
  ],
  series: [
    {
      name: "优秀",
      type: "bar",
      stack: "Ad",
      emphasis: {
        focus: "series",
      },
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "良好",
      type: "bar",
      stack: "Ad",
      emphasis: {
        focus: "series",
      },
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "及格",
      type: "bar",
      stack: "Ad",
      emphasis: {
        focus: "series",
      },
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
    {
      name: "不及格",
      type: "bar",
      stack: "Ad",
      emphasis: {
        focus: "series",
      },
      label: {
        show: true,
        color: "#fff",
        formatter: (value) => {
          return value.value + "%";
        },
      },
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  ],
};

export const overall_health = {
  title: {
    text: "体质健康总体达标率",
    bottom: "0",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  series: [
    {
      name: "体质健康总体达标率",
      type: "pie",
      radius: "75%",
      center: ["50%", "50%"],
      avoidLabelOverlap: false,
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      labelLine: {
        show: true,
      },
      data: [
        { value: 0, name: "优秀" },
        { value: 0, name: "良好" },
        { value: 0, name: "及格" },
        { value: 0, name: "不及格" },
      ],
    },
  ],
};

export const overall_homework = {
  title: {
    text: "体育运动作业完成情况",
    bottom: "0",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  series: [
    {
      name: "体育运动作业完成情况",
      type: "pie",
      radius: "75%",
      center: ["50%", "50%"],
      avoidLabelOverlap: false,
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },
      labelLine: {
        show: true,
      },
      data: [
        { value: 0, name: "优秀" },
        { value: 0, name: "良好" },
        { value: 0, name: "及格" },
        { value: 0, name: "不及格" },
      ],
    },
  ],
};

export const overall_knowledge = {
  title: {
    text: "知识素养作业完成情况",
    bottom: "0",
    left: "center",
    textStyle: {
      fontWeight: "normal",
      fontSize: "18px",
    },
  },
  tooltip: {
    trigger: "item",
    formatter: function (record) {
      return `${record.name} \n ${record.value}%`;
    },
  },
  series: [
    {
      name: "知识素养作业完成情况",
      type: "pie",
      radius: "75%",
      center: ["50%", "50%"],
      avoidLabelOverlap: false,
      color: [
        "#5D77F9",
        "#84B7F9",
        "#E5F3FF",
        "#5F9CF8",
        "#E5F3FF",
        "#90B5FF",
        "#5087EC",
        "#2478F2",
        "#8BD0DB",
        "#67B3E8",
        "#6DC8E1",
        "#B6E3F0",
      ],
      label: {
        show: true,
        textStyle: {
          color: "#444444",
          fontSize: 10,
          lineHeight: 14,
        },
        formatter: function (record) {
          return `${record.name} \n ${record.value}%`;
        },
      },

      labelLine: {
        show: true,
      },
      data: [
        { value: 0, name: "优秀" },
        { value: 0, name: "良好" },
        { value: 0, name: "及格" },
        { value: 0, name: "不及格" },
      ],
    },
  ],
};
