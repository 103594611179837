<template>
  <div>
    <div class="flexSb">
      <!-- <div class="header_title">{{ title }}</div> -->
      <div v-if="title" class="header_title">{{ title }}</div>
      <el-breadcrumb class="crumb flexSt" v-if="show" separator="/">
        <el-breadcrumb-item :to="{ path: '/index' }">
          <div v-if="currentBreadcrumblist.length == 0" class="ad">
            <i class="iconfont icon-antOutline-home"></i>首页
          </div>
          <i v-else class="iconfont icon-antOutline-home"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item
          v-for="(item, index) in currentBreadcrumblist"
          :index="index">
          <a v-if="index != 0" :href="'/' + item.name">{{ item.pathname }} </a>

          <span v-if="index == 0">
            {{ item.pathname }}
          </span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="flexSe">
        <!-- <el-select
          class="sel"
          @change="handleChangeSchool"
          v-model="schoolID"
          placeholder="请选择学校"
        >
          <el-option
            v-for="item in areaoptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select> -->
        <el-select
          @change="handleChangeTime"
          v-model="value"
          placeholder="请选择学年">
          <el-option
            v-for="item in options"
            :key="item.school_year"
            :label="item.school_year"
            :value="item.school_year">
          </el-option>
        </el-select>
      </div>
    </div>
  </div>
</template>
<script>
import { schoolYear } from "@/api/evaluation";
import { getSchoolID } from "../../../utils/auth";
export default {
  props: {
    //   options: {
    //     type: Array,
    //     default: function () {
    //       return [];
    //     },
    //   },
    // value: { type: String, default: "" },
    currentBreadcrumblist: {
      type: Array,
      default: function () {
        return [];
      },
    },
    title: { type: String, default: "" },
    show: { type: Boolean, default: false },
  },
  data() {
    return {
      options: [],
      areaoptions: [
        {
          value: "-1",
          label: "全区",
        },
        {
          value: "1",
          label: "测试学校",
        },
      ],
      value: "",
      startTime: "",
      endTime: "",
      schoolID: "-1",
      School_id: getSchoolID(),
    };
  },
  created() {
    this.handleGetschoolYear();
  },
  methods: {
    handleChangeSchool(val) {
      this.areaoptions.forEach((item, index) => {
        if (item.value == val) {
          this.schoolName = item.label;
          this.schoolID = val;
          this.$emit("handleChangeSchool", {
            schoolName: this.schoolName,
            schoolID: this.schoolID,
          });
        }
      });
    },
    handleGetschoolYear() {
      console.log(this.$route, "this.$route");
      schoolYear().then((res) => {
        if (res.data.length > 0) {
          this.options = res.data;
          if (
            this.$route.name == "teachingModel" ||
            this.$route.name == "studentLiteracy"
          ) {
            if (this.School_id == "72") {
              this.value = "2020-2021学年";
              this.startTime = "2020-09-01";
              this.endTime = "2021-09-01";
            }
          } else {
            this.value = res.data[0].school_year;
            this.startTime = res.data[0].start_time;
            this.endTime = res.data[0].end_time;
          }

          this.$emit("handleChangeTime", {
            startTime: this.startTime,
            endTime: this.endTime,
            value: this.value,
          });
        }
      });
    },
    handleChangeTime(val) {
      console.log(val);
      this.options.forEach((item, index) => {
        if (item.school_year == val) {
          this.startTime = item.start_time;
          this.endTime = item.end_time;
          this.$emit("handleChangeTime", {
            startTime: this.startTime,
            endTime: this.endTime,
            value: this.value,
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.sel {
  margin-right: 20px;
}
.header_title {
  color: rgba(16, 16, 16, 1);
  font-size: 26px;
}
.crumb {
  // height: 75px;
  // line-height: 75px;
  font-size: 18px;
  margin-left: 23px;
  margin-top: 18px;
}
</style>
